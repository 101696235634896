import React, { useCallback, useContext, useRef, useState } from "react"
import { Font } from "@react-pdf/renderer"
import { emptyObject, NCAAB_S16_GAME_INSTANCE_UID } from "@cbs-sports/sports-shared-client/build/cjs/utils/constant-utils"
import { BracketToolbarWarp, BtnLabel, EditAvatarMenuWrap, Separator } from "./BracketToolBar.styles"
import Button, { StyledButton } from "@cbs-sports/sports-shared-client/build/cjs/components/Button"
import ListItemIcon from "@cbs-sports/sports-shared-client/build/cjs/components/ListItemIcon"
import ImportSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Import"
import PickUtils from "../../../../../common/pick-utils"
import PoolDataContext from "../../../../Contexts/PoolDataContext"
import Menu, { MenuItem, MenuLink } from "@cbs-sports/sports-shared-client/build/cjs/components/Menu"
import PromoModal from "@cbs-sports/sports-shared-client/build/cjs/components/Brackets/Promo/PromoModal"
import { ANCHORS } from "@cbs-sports/sports-shared-client/build/cjs/components/Popover/utils/constants"
import HorizontalSeparator from "@cbs-sports/sports-shared-client/build/cjs/components/HorizontalSeparator"
import DeleteSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Delete"
import MoreSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/More"
import EditSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Edit"
import PrintSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Print"
import AvatarSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Avatar"
import RulesSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Rules"
import TrophySvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Trophy"
import StandingsSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Standings"
import ImportBracketModal from "../EntryModals/ImportBracketModal"
import UpsertEntryNameModal from "../EntryModals/UpsertEntryNameModal"
import EditEntryAvatarModal from "../EntryModals/EditEntryAvatarModal"
import DeleteBracketModal from "../EntryModals/DeleteBracketModal"
import PoolRulesModal from "../EntryModals/PoolRulesModal/PoolRulesModal"
import { useQuery } from "@apollo/client/react/hooks"
import { toast } from "@cbs-sports/sports-shared-client/build/cjs/components/Toast"
import { PoolDetailsQuery, PoolDetailsQueryVariables } from "../../../../../__generated__/PoolDetailsQuery"
import { POOL_DETAILS_QUERY } from "../../../queries"
import Modal from "@cbs-sports/sports-shared-client/build/cjs/components/Modal"
import { AutoFillModalHeaderStyled } from "@cbs-sports/sports-shared-client/build/cjs/components/Brackets/AutoFill/AutoFill.styles"
import AutoPickContainer, { AutoPickContainerProps } from "../../containers/AutoPick/AutoPickContainer"
import ToolBarStat from "./ToolBarStat"
import { Link, useHistory, useRouteMatch } from "react-router-dom"
import { withoutDomain } from "../../../../utils/url-utils"
import { isCurrentUserLoggedIn } from "../../../../utils/data-utils"
import Analytics from "../../../../utils/analytics"
import constants from "../../../../../common/constants"
import MensBracketSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/MensBracket"
import { isNCAAWTournamentMatcher } from "../../../../../common/common-utils-helpers"
import WomensBracketSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/WomensBracket"
import AutofillInterstitialModal from "../EntryModals/AutofillInterstitialModal"

type ModalTypes = null | "import" | "edit-bracket-name" | "pool-rules" | "delete-bracket" | "autofill"
interface BracketToolbarProps {
  onAutoFillClick?: (event: React.SyntheticEvent) => Promise<unknown> | void
  onFacebookClick?: (event: React.SyntheticEvent) => Promise<unknown> | void
  onTwitterClick?: (event: React.SyntheticEvent) => Promise<unknown> | void
  onPrintClick?: (event: React.SyntheticEvent) => Promise<unknown> | void
  onAutoPickData: AutoPickContainerProps["onAutoPickData"]
  onImportClose?: (saved?: boolean) => Promise<unknown> | void
  pickUtils: PickUtils
  showAutofillInterstitial?: boolean
}

const lastBoxStyle: React.CSSProperties = {
  marginRight: "0.5rem",
}

const tallBtnStyle: React.CSSProperties = {
  height: "3.5rem",
}

const btnStyle: React.CSSProperties = {
  margin: "0.25rem 0",
}

//Register FSIndustrie font for the printable bracket
const FS_INDUSTRIE_FONT_SRC = `${constants.PROTOCOL}//sports.cbsimg.net/fonts/fs-industrie/variable/standard/FSIndustrieVFStd.ttf`
Font.register({
  family: "FSIndustrie",
  src: FS_INDUSTRIE_FONT_SRC,
})
Font.registerHyphenationCallback((word) => [word])

const BracketToolbar = ({
  onAutoFillClick,
  onAutoPickData,
  onPrintClick,
  onImportClose,
  pickUtils,
  showAutofillInterstitial = false,
  ...rest
}: BracketToolbarProps) => {
  const poolData = useContext(PoolDataContext)
  const [moreElement, setMoreElement] = useState<null | HTMLElement>(null)
  const [showInterstitial, setShowInterstitial] = useState(true)

  const [fileImage, setFileImage] = useState<string>("")
  const [modal, setModal] = useState<ModalTypes>(null)
  const [sponsorId, setSponsorId] = useState<string | null>(null)
  const fileRef = useRef<HTMLInputElement>(null)
  const history = useHistory()
  const lobbyMatch = useRouteMatch(constants.BRACKET_LOBBY_ROUTE)

  const {
    detailedEntry,
    poolDetail,
    poolId,
    myEntriesForArea,
    entryId,
    currentUser,
    centralBracketState,
    isChallengePool,
    gameInstanceUid,
    isManagerModeActive,
  } = poolData || emptyObject
  const { avatarUrl, name: entryName, canDeleteEntry, poolRank, fantasyPoints, maxPoints, isMine } = detailedEntry || emptyObject
  const { name: poolName, url: poolUrl } = poolDetail || emptyObject
  const isLoggedIn = isCurrentUserLoggedIn(currentUser)
  const inSweet16Variant = gameInstanceUid === NCAAB_S16_GAME_INSTANCE_UID && constants.SWEET_16_ENABLED

  const { data: poolDetailsData } = useQuery<PoolDetailsQuery, PoolDetailsQueryVariables>(POOL_DETAILS_QUERY, {
    variables: {
      poolId,
    },
  })

  const handleCloseImport = (saved?: boolean) => {
    setModal(null)
    if (typeof onImportClose === "function") {
      onImportClose(saved)
    }
  }

  const handleMoreClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMoreElement(event.currentTarget)
  }

  const handleMoreClose = () => {
    setMoreElement(null)
  }

  const hideModal = () => {
    setModal(null)
  }

  const redirectToLobby = () => {
    window.location.href = lobbyMatch?.url ?? "/"
    // history.push(lobbyMatch?.url ?? "/")
    toast.snackbar("Your bracket has been deleted")
  }

  const onMenuClick = (section: ModalTypes) => {
    Analytics.trackAction("fill bracket", "header", section?.replace("-", " ") || "")
    if (!isLoggedIn && section === "delete-bracket") {
      //TODO: YHB clear cookie
      history.push(lobbyMatch?.url || "")
    } else {
      setModal(section)
    }
    setMoreElement(null)
  }

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMoreElement(null)
    const file = event.currentTarget.files && event.currentTarget.files[0]
    if (file) {
      const type = file.type?.split("/")
      if (type?.length > 0) {
        const isImage = type[0] === "image"
        if (isImage) {
          const reader = new FileReader()
          reader.onload = () => {
            setFileImage((reader.result as any) as string)
            if (fileRef.current) {
              fileRef.current.value = "" // reseting the input
            }
          }

          if (file) {
            reader.readAsDataURL(file)
          }
        }
      }
    }
  }

  const handleAutoFillClick = useCallback((selectedOption: string, sponsorName: string) => {
    if (sponsorName === "NISSAN_SECTION") {
      setSponsorId(selectedOption)
      setTimeout(() => {
        hideModal()
        setSponsorId(null)
      }, 2500)
    }
    console.log("selectedOption: ", selectedOption)
  }, [])

  const onAutoFillIntertsitial = () => {
    setShowInterstitial(false)
    setTimeout(() => {
      setModal("autofill")
    }, 100)
  }

  const bracketTree = pickUtils.bracketPeriodTree
  const isLocked = inSweet16Variant
    ? false // Boolean(centralBracketState?.bracketState?.sweetSixteenBracketState.isLocked)
    : Boolean(centralBracketState?.bracketState?.isLocked)

  let maxWidth: number | undefined = undefined
  let minWidth: number | undefined = undefined
  if (bracketTree) {
    maxWidth = bracketTree.visualOptions.width
    minWidth = bracketTree.visualOptions.minWidth
  }

  const autoFillModal = isLocked ? null : (
    <Modal
      modalType={"takeover"}
      padded={false}
      isOpen={modal === "autofill"}
      afterClose={hideModal}
      closeBtnAction={hideModal}
      variant="white"
      header={<AutoFillModalHeaderStyled />}
    >
      <AutoPickContainer onCancel={hideModal} onAutoPickData={onAutoPickData} onPromoSelect={handleAutoFillClick} />
    </Modal>
  )
  const autoFillAfterModal = isLocked ? null : (
    <Modal modalType="modal" padded={false} isOpen={!!sponsorId} variant={"white"}>
      {sponsorId && <PromoModal promoSelection={sponsorId || ""} />}
    </Modal>
  )

  const autofillInterstitial = (
    <AutofillInterstitialModal
      onAutoFill={onAutoFillIntertsitial}
      onManualMode={() => setShowInterstitial(false)}
      isOpen={showAutofillInterstitial && showInterstitial}
      gameInstanceUid={gameInstanceUid}
    />
  )

  const userHasOtherEntriesWithPicks = (myEntriesForArea()?.currentEntriesForArea || []).some((entry) => entry.id !== entryId && entry.hasMadeAPick)
  const poolLogoUrl = isLoggedIn ? avatarUrl : poolDetailsData?.pool?.avatarUrl
  const isMyEntry = detailedEntry?.isMine
  const isWomenBracket = isNCAAWTournamentMatcher.test(gameInstanceUid || "")

  return (
    <BracketToolbarWarp maxWidth={maxWidth} minWidth={minWidth}>
      <div className="toolbar-left-side">
        <div className="toolbar-entry-logo">
          {(poolLogoUrl && <img src={poolLogoUrl} alt="Pool Logo" />) || (isWomenBracket ? <WomensBracketSvg /> : <MensBracketSvg />)}
        </div>
        <div className="toolbar-entry-pool-information">
          <div className="toolbar-entry-pool-information-top">
            <span className="entry-name">{isLoggedIn ? entryName : poolName}</span>
            {(isMine || isManagerModeActive) && (
              <>
                <Button variant="clear" onClick={handleMoreClick} withoutText size="sm">
                  <ListItemIcon size="lg">
                    <MoreSvg />
                  </ListItemIcon>
                </Button>
                <Menu anchorEl={moreElement} onClose={handleMoreClose} open={Boolean(moreElement)} origin="left" anchor={ANCHORS.BOTTOM_LEFT}>
                  {isLoggedIn && (
                    <>
                      {isChallengePool && (
                        <>
                          <MenuItem disabled={isLocked}>
                            <MenuLink onClick={() => onMenuClick("edit-bracket-name")}>
                              <ListItemIcon alignment="left">
                                <EditSvg />
                              </ListItemIcon>
                              Edit bracket name
                            </MenuLink>
                          </MenuItem>
                          <HorizontalSeparator />
                        </>
                      )}
                      <MenuItem>
                        <MenuLink as="div">
                          <EditAvatarMenuWrap data-cy="menu_edit_avatar">
                            <input id="pool_avatar" type="file" className="input-file" onChange={onFileChange} accept="image/*" ref={fileRef} />
                            <ListItemIcon alignment="left">
                              <AvatarSvg />
                            </ListItemIcon>
                            Edit avatar
                          </EditAvatarMenuWrap>
                        </MenuLink>
                      </MenuItem>
                    </>
                  )}
                  <MenuItem>
                    <MenuLink onClick={() => onMenuClick("pool-rules")}>
                      <ListItemIcon alignment="left">
                        <RulesSvg />
                      </ListItemIcon>
                      Pool scoring rules
                    </MenuLink>
                  </MenuItem>
                  <HorizontalSeparator />
                  <MenuItem disabled={!canDeleteEntry && isLoggedIn}>
                    <MenuLink onClick={() => onMenuClick("delete-bracket")}>
                      <ListItemIcon alignment="left">
                        <DeleteSvg />
                      </ListItemIcon>
                      Delete bracket
                    </MenuLink>
                  </MenuItem>
                </Menu>
              </>
            )}
          </div>
          <span className="pool-name">{isLoggedIn ? poolName : ""}</span>
        </div>
      </div>
      <div className="toolbar-right-side">
        {!isLocked && (
          <>
            {!inSweet16Variant && (
              <>
                <Button variant="clear" onClick={() => onMenuClick("import")} disabled={!userHasOtherEntriesWithPicks || !isMyEntry}>
                  <ListItemIcon alignment="left">
                    <ImportSvg />
                  </ListItemIcon>
                  <BtnLabel>Import</BtnLabel>
                </Button>
                <Separator />
                <Button variant="clear" onClick={() => onMenuClick("autofill")}>
                  <ListItemIcon alignment="left">
                    <EditSvg />
                  </ListItemIcon>
                  <BtnLabel>Autofill</BtnLabel>
                </Button>
                <Separator />
              </>
            )}
            <Button variant="clear" onClick={onPrintClick}>
              <ListItemIcon alignment="left">
                <PrintSvg />
              </ListItemIcon>
              <BtnLabel>Print</BtnLabel>
            </Button>
            <Separator />
            <StyledButton className="variant--clear size--md" as={Link} to={`${withoutDomain(poolUrl)}/standings`} style={btnStyle}>
              <ListItemIcon alignment="left">
                <StandingsSvg />
              </ListItemIcon>
              <BtnLabel>Standings</BtnLabel>
            </StyledButton>
          </>
        )}
        {isLocked && (
          <>
            <ToolBarStat label="Rank" value={poolRank} icon={<TrophySvg />} />
            <ToolBarStat label="Points" value={fantasyPoints} />
            <ToolBarStat label="Max PTS" value={maxPoints} style={lastBoxStyle} />
            <StyledButton className="variant--secondary size--md" as={Link} to={`${withoutDomain(poolUrl)}/standings`} style={tallBtnStyle}>
              <ListItemIcon alignment="left">
                <StandingsSvg />
              </ListItemIcon>
              <BtnLabel>Standings</BtnLabel>
            </StyledButton>
          </>
        )}
      </div>
      <ImportBracketModal close={handleCloseImport} isOpen={modal === "import"} />
      <UpsertEntryNameModal close={hideModal} isOpen={modal === "edit-bracket-name"} />
      <EditEntryAvatarModal close={() => setFileImage("")} isOpen={!!fileImage} file={fileImage} />
      <DeleteBracketModal close={hideModal} onEntryRemoved={redirectToLobby} isOpen={modal === "delete-bracket"} />
      <PoolRulesModal isOpen={modal === "pool-rules"} pool={poolDetailsData?.pool} close={hideModal} noBorder mode="rules" />
      {autoFillModal}
      {autoFillAfterModal}
      {autofillInterstitial}
    </BracketToolbarWarp>
  )
}
export default BracketToolbar
