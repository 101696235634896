import React, { FC } from "react"
import styled from "styled-components"
import { pxToRem } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import theme from "@cbs-sports/sports-shared-client/build/cjs/utils/BracketTheme"

import GenericEntryModal, { noop } from "./GenericEntryModal"
import WomensBracketSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/WomensBracket"
import MensBracketSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/MensBracket"
import { isNCAAWTournamentMatcher } from "../../../../../common/common-utils-helpers"
import ManualIcon from "../../../../components/icons/Manual"
import SportsLine from "../../../../components/icons/SportsLine"
import { ChevRightSvg } from "@cbs-sports/sports-shared-client/build/cjs/components/icons"

const DEFAULT_TITLE = "How would you like to fill out your bracket?"
const ACTION_TITLE = "Select an option to continue"

const Wrapper = styled.div`
  width: ${pxToRem(408)};
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  padding-bottom: 1rem;

  & > .interstitial__modal__header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    & > svg {
      width: 5rem;
      height: 5rem;
    }
    & > .interstitial__modal__title {
      font-family: ${theme.fonts.family.base};
      font-size: 1.25rem;
      font-weight: 700;
      line-height: 1.5rem;
      text-align: center;
      letter-spacing: -0.1px;
      color: ${theme.colors.overLight.white20};
    }
  }

  & > .interstitial__modal__actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1.5rem;
    & > .interstitial__modal__action_title {
      font-family: ${theme.fonts.family.base};
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.25rem;
      text-align: left;
      margin-bottom: -0.5rem;
    }

    & > .interstitial__modal__cta-section {
      padding: 1rem;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      border: solid 1px ${theme.colors.overLight.white90};
      border-radius: 0.5rem;
      align-items: center;
      gap: 1rem;
      & > .cta-section__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.5rem;
        height: 2.5rem;
        & > svg {
          width: 100%;
          height: 100%;
        }
      }

      & > .cta-section__icon.manual-icon {
        & > svg {
          width: 80%;
          height: 80%;
        }
      }

      & > .cta-section__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1;

        & > .cta-section__text {
          display: flex;
          flex-direction: column;
          line-height: 1.5rem;
          letter-spacing: 0.25px;
          text-align: left;
          font-family: ${theme.fonts.family.base};

          & > .cta-section__text-title {
            font-size: 1.125rem;
            font-weight: 700;
            color: ${theme.colors.overLight.white20};
          }
          & > .cta-section__text-description {
            font-size: 1rem;
            font-weight: 500;
            color: ${theme.colors.overLight.white40};
          }
        }

        & > .cta-section__chev-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 2rem;
          height: 2rem;
          min-width: 2rem;

          & > svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  @media (hover: hover) {
    & > .interstitial__modal__actions {
      & > .interstitial__modal__cta-section {
        & :hover {
          background-color: ${theme.colors.overLight.blue1};
        }
      }
    }
  }
`

interface IAutofillInterstitialModalProps {
  isOpen: boolean
  gameInstanceUid: string
  onAutoFill: () => void
  onManualMode: () => void
}

const AutofillInterstitialModal: FC<IAutofillInterstitialModalProps> = ({ onAutoFill, onManualMode, gameInstanceUid, ...rest }) => {
  const isWomenBracket = isNCAAWTournamentMatcher.test(gameInstanceUid || "")

  return (
    <GenericEntryModal onBackgroundClick={noop} onEscapeKeydown={noop} actionProps={{ dense: true }} {...rest} closeBtnAction={onManualMode}>
      <Wrapper>
        <div className="interstitial__modal__header">
          {isWomenBracket ? <WomensBracketSvg /> : <MensBracketSvg />}
          <span className="interstitial__modal__title">{DEFAULT_TITLE}</span>
        </div>
        <div className="interstitial__modal__actions">
          <span className="interstitial__modal__action_title">{ACTION_TITLE}</span>
          <div className="interstitial__modal__cta-section" onClick={onAutoFill}>
            <div className="cta-section__icon">
              <SportsLine />
            </div>
            <div className="cta-section__content">
              <div className="cta-section__text">
                <div className="cta-section__text-title">Autofill</div>
                <div className="cta-section__text-description">
                  Enter picks using our Free or Premium options. You can always change your picks later.
                </div>
              </div>
              <div className="cta-section__chev-icon">
                <ChevRightSvg />
              </div>
            </div>
          </div>
          <div className="interstitial__modal__cta-section" onClick={onManualMode}>
            <div className="cta-section__icon manual-icon">
              <ManualIcon />
            </div>
            <div className="cta-section__content">
              <div className="cta-section__text">
                <div className="cta-section__text-title">Manual</div>
                <div className="cta-section__text-description">
                  Build your perfect bracket. Every pick is yours, show your skills and predict the upsets.
                </div>
              </div>
              <div className="cta-section__chev-icon">
                <ChevRightSvg />
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </GenericEntryModal>
  )
}

export default AutofillInterstitialModal
