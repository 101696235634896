/* eslint-disable react-hooks/rules-of-hooks */
import React, { useCallback, useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { IBracketProps } from "../containers/IPicksProps"
import { getStringParam } from "../../../../common/url-utils"
import { convertVariantToDisplayMode } from "@cbs-sports/sports-shared-client/build/cjs/utils/common-utils-helpers"
import { newPalette } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import PicksExporterUtil, { IExistingPicksExporterData } from "@cbs-sports/sports-shared-client/build/cjs/utils/picks-exporter-util"
import Button from "@cbs-sports/sports-shared-client/build/cjs/components/Button"
import PrintIcon from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Print"
import ListItemIcon from "@cbs-sports/sports-shared-client/build/cjs/components/ListItemIcon"
import InfoIcon from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Info"
import { canUseDom } from "@cbs-sports/sports-shared-client/build/cjs/utils/dom-utils"
import { getVisualOptions } from "@cbs-sports/sports-shared-client/build/cjs/utils/VisualOptions"
import BracketSkeleton from "@cbs-sports/sports-shared-client/build/cjs/components/Brackets/BracketSkeleton"
import ConfirmClearAll from "@cbs-sports/sports-shared-client/build/cjs/components/Brackets/ConfirmClearAll"
import MatchUpCard from "@cbs-sports/sports-shared-client/build/cjs/components/Brackets/MatchUpCard"
import OnFirstPickModal from "@cbs-sports/sports-shared-client/build/cjs/components/Brackets/OnFirstPickModal"
import LockWarningModal from "@cbs-sports/sports-shared-client/build/cjs/components/Brackets/LockWarningModal"
import { IPartialBracketVisualOptions, IPickUtilsPick, IPickUtilsPicks } from "@cbs-sports/sports-shared-client/build/types/utils/common-utils-types"
import bracketTheme from "@cbs-sports/sports-shared-client/build/cjs/utils/BracketTheme"
import { GameSportTypeEnumType } from "@cbs-sports/sports-shared-client/build/cjs/types/globalTypes"
import { MatchUpAnalysisProps } from "@cbs-sports/sports-shared-client/build/types/components/Brackets/MatchUpAnalysis/MatchUpAnalysis"
import { MatchupAnalysisQuery, MatchupAnalysisQueryVariables } from "../../../../__generated__/MatchupAnalysisQuery"
import { MATCHUP_ANALYSIS_QUERY } from "../../queries"
import BracketUpsellComponent from "@cbs-sports/sports-shared-client/build/cjs/components/Brackets/BracketSkeleton/BracketUpsell"
import Modal from "@cbs-sports/sports-shared-client/build/cjs/components/Modal"
import MatchUpAnalysis, {
  MatchUpAnalysisHeader,
} from "@cbs-sports/sports-shared-client/build/cjs/components/Brackets/MatchUpAnalysis/MatchUpAnalysis"
import { useQuery } from "@apollo/client/react/hooks"
import {
  emptyObject,
  emptyArray,
  NCAAB_GAME_INSTANCE_UID,
  NCAAW_GAME_INSTANCE_UID,
  NCAAB_S16_GAME_INSTANCE_UID,
  BRACKET_FINAL_FOUR_LOGO_ID,
} from "@cbs-sports/sports-shared-client/build/cjs/utils/constant-utils"
import ImportBracketModal from "./EntryModals/ImportBracketModal"
import PoolDataContext, { PoolDataContextType } from "../../../Contexts/PoolDataContext"
import BracketToolbar from "./BracketToolBar/BracketToolBar"
import { OptionsMenu } from "../styles/Picks.styles"
import PoolBracketsDropdown from "./PoolBracketsDropdown"
import Toggle from "../../../components/Toggle"
import { palette, pxToRem } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import { isCurrentUserLoggedIn } from "../../../utils/data-utils"
import BracketLoginModal from "./EntryModals/LoginModal"
import { getAuthSearch } from "../../../utils/url-utils"
import { Link, Prompt, useHistory, useRouteMatch } from "react-router-dom"
import History from "history"
import { buildPickUtils, extractPoolSettings } from "../../../hooks/usePoolData"
import PickUtils from "../../../../common/pick-utils"
import { AutoPickQuery_autopick } from "../../../../__generated__/AutoPickQuery"
import constants, { managerModeHeaderName, registeredTrademark } from "../../../../common/constants"
import useUpsertEntryMutation from "../../../hooks/useUpsertEntryMutation"
import AnalyticScreen from "../../../components/AnalyticsScreen"
import BracketPresentedBy from "./BracketPresentedBy"
import Analytics, { trackSharedLib } from "../../../utils/analytics"
import DiscardChangesConfirmation from "./EntryModals/DiscardChangesConfirmation"
import PrintableBracketViewer from "./PrintableBracket/PrintableBracketViewer"
import { trackingElements } from "../../../utils/bracket-tracking-elts"
import SearchEntriesDropdown from "../containers/SearchEntriesDropdown/"
import UpsertEntryNameModal from "./EntryModals/UpsertEntryNameModal"
import PicksStatus from "./PicksStatus"
import { getVisualOptionsGameInstanceUID, isNCAAWTournamentMatcher } from "../../../../common/common-utils-helpers"
import CookieUtils from "../../../utils/cookie-utils"
import IncompleteBracketConfirmationModal from "./EntryModals/IncompleteBracketConfirmationModal"
import { getRulesLink } from "../../../../common/game-text"
import GenericConfirmationModal from "./EntryModals/GenericConfirmationModal"

type BracketSubmitStatus = "idle" | "request" | "warning" | "saving"
type BracketSubmitErrorType = "Incomplete Bracket" | "Tiebreaker Not Entered"
type BracketSubmitState = {
  submitState: BracketSubmitStatus
  error?: string
  errorType?: BracketSubmitErrorType
}
type BracketSubmitAction =
  | { type: "submit" }
  | { type: "successful_check" }
  | { type: "unsuccessful_check"; error: string; errorType: BracketSubmitErrorType }
  | { type: "confirm" }
  | { type: "cancel" }
  | { type: "completed" }

//NOTE RH: Per requirements, the tiebreaker value should accept a maximum of 3 characters, digits only
const TIEBREAKER_VALUE_REGEX = /^\d{0,3}$/

const SingleBracketWrapper = styled.div`
  padding: var(--grid-gap);

  &.extra-padding {
    padding-bottom: 8rem; //To prevent the PicksCount to overlap the champion card in Sweet16 game, due to lack of spacing
  }

  @media (max-width: ${pxToRem(975)}) {
    padding-right: var(--grid-gap);
    padding-left: var(--grid-gap);
  }

  .bracket-nav__container {
    margin-bottom: var(--grid-gap);
  }
`

const SuccessfulInfoIcon = styled(InfoIcon)`
  color: ${newPalette.green};
`

interface MatchUpAnalysisInfo extends Omit<MatchUpAnalysisProps, "analysisData" | "isLoading" | "onSwitchTeam"> {
  homeTeamAbbrev: string
  awayTeamAbbrev: string
  leftSidePlayInTeamAbbrev?: string
  rightSidePlayInTeamAbbrev?: string
  sportType: GameSportTypeEnumType
}

const SingleBracket = ({ hasManagerRole, mode, updateMode }: IBracketProps) => {
  // PoolDataContext
  const poolDataContext: PoolDataContextType = useContext(PoolDataContext)

  const reducer: React.Reducer<BracketSubmitState, BracketSubmitAction> = (state, action) => {
    switch (action.type) {
      case "submit":
        return { submitState: "request" }
      case "successful_check":
      case "confirm":
        return { ...state, submitState: "saving" }
      case "cancel":
      case "completed":
        return { ...state, submitState: "idle" }
      case "unsuccessful_check":
        return {
          submitState: "warning",
          error: action.error,
          errorType: action.errorType,
        }
      default:
        return state
    }
  }
  const initialSubmitState: BracketSubmitState = { submitState: "idle" }
  const [bracketSubmitState, dispatch] = React.useReducer(reducer, initialSubmitState)
  const [showIncompleteWarningModal, setShowIncompleteWarningModal] = useState<boolean>(false)

  const [showPrintable, setShowPrintable] = useState<boolean>(false)
  const [showNameYourEntryModal, setShowNameYourEntryModal] = useState<boolean>(false)
  const closePrintableModal = () => setShowPrintable(false)
  const {
    isInComingSoon,
    centralBracketState,
    gameInstanceUid,
    poolId,
    isManagerModeActive,
    isChallengePool,
    currentUser,
    isCbsAppWebview,
    detailedEntry,
    productSeason,
    entryId,
    periodId,
    deviceType,
    poolDetail,
    myEntriesForArea,
    entryDetailsIsLoadingOrChanging,
  } = poolDataContext || emptyObject
  const inSweet16Variant = constants.SWEET_16_ENABLED && gameInstanceUid === NCAAB_S16_GAME_INSTANCE_UID
  const isWomens = isNCAAWTournamentMatcher.test(gameInstanceUid)
  const hard_coded_game_instance = isWomens ? NCAAW_GAME_INSTANCE_UID : NCAAB_GAME_INSTANCE_UID

  const isLoggedIn = isCurrentUserLoggedIn(currentUser)
  const useCookieForPicks = !isLoggedIn && isChallengePool

  const { alreadyHasChallengeEntry } = myEntriesForArea()

  const cookieKey = entryId
    ? `${gameInstanceUid}_entry_${constants.BRACKET_LOCAL_STORAGE_KEY}_${entryId.replace(/=/, "")}`
    : `${gameInstanceUid}_${constants.BRACKET_LOCAL_STORAGE_KEY}`

  const [matchUpAnalysisInfo, setMatchUpAnalysisInfo] = useState<MatchUpAnalysisInfo | null>(null)
  const currentPickUtils = React.useRef<PickUtils | null>(null)

  const [modal, setModal] = useState<
    | "autofill"
    | "clearpicks"
    | "initialpicks"
    | "matchup-analysis"
    | "game-videos"
    | "log-in"
    | "lock-warning"
    | "on-submit-error"
    | "manager-changes"
    | null
  >(null)
  const [wipPicks, setWipPicks] = React.useState<IPickUtilsPicks>([])
  // TODO qac: we absolutely should use the prop pickUtils rather than build a second one...
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const pickUtils = buildPickUtils(poolDataContext!, currentPickUtils.current, wipPicks, detailedEntry?.id)
  if (currentPickUtils.current !== pickUtils) {
    currentPickUtils.current = pickUtils
  }
  const isBracketLock = Boolean(centralBracketState?.bracketState?.isLocked)

  const initialIsLocked =
    !isManagerModeActive &&
    (inSweet16Variant ? false /*Boolean(centralBracketState?.bracketState?.sweetSixteenBracketState.isLocked)*/ : isBracketLock)

  const [isLocked, setIsLocked] = React.useState<boolean>(initialIsLocked)
  const [isPersistingPicks, setIsPersistingPicks] = useState<Record<string, boolean>>({ saving: false, success: false })
  const [bindMouseoverPropogation, setBindMouseoverPropogation] = useState<boolean>(false)
  const [existingPicksExporterData, setExistingPicksExporterData] = useState<IExistingPicksExporterData | undefined>(undefined)
  const [viewingTournamentRounds, setviewingTournamentRounds] = useState<number>(2)
  const [showImportBracketModal, setShowImportBracketModal] = React.useState<boolean>(false)
  const [hasChanges, setHasChanges] = React.useState<boolean>(false)
  const history = useHistory()
  const lobbyMatch = useRouteMatch(constants.BRACKET_LOBBY_ROUTE)

  //Tiebreaker handler
  const poolSettings = poolDataContext ? extractPoolSettings(poolDataContext) : emptyObject
  const poolHasTiebreaker = poolSettings?.mainTiebreaker === "TOTAL_SCORE"
  const showUpsellBox = !alreadyHasChallengeEntry && !isChallengePool && !detailedEntry?.isOfflineEntry && !!detailedEntry?.isMine
  const isTiebreakerDisabled = !poolHasTiebreaker || isLocked
  const [tiebreakerQuestion] = pickUtils?.getTiebreakerQuestions() ?? emptyArray
  const [tiebreakerAnswerValue, setTiebreakerAnswerValue] = useState<string>(() => detailedEntry?.tiebreakerAnswers?.[0]?.value ?? "")
  const [isUpsellValue, setIsUpsellValue] = useState<boolean>(() => {
    if (showUpsellBox) {
      const data = CookieUtils.getCookie(CookieUtils.BRACKET_UPSELL_COOKIE_NAME)
      if (data) {
        return data === "true"
      }
      return true
    }
    return false
  })
  const handleTiebreakerChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = String(e.target.value ? Number(e.target.value) : e.target.value)
    const isValidValue = TIEBREAKER_VALUE_REGEX.test(newValue)
    if (isValidValue) {
      setTiebreakerAnswerValue(newValue)
    }
  }, [])
  const handleUpsellChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.checked
    CookieUtils.setCookie(CookieUtils.BRACKET_UPSELL_COOKIE_NAME, newValue.toString())
    setIsUpsellValue(e.target.checked)
  }, [])

  const [showNavigationAlert, setShowNavigationAlert] = useState<boolean>(false)
  const [navigateTo, setNavigateTo] = useState<History.Location | string | null>(null)
  const [navigationConfirmed, setNavigationConfirmed] = useState<boolean>(false)

  useEffect(() => {
    setTiebreakerAnswerValue(detailedEntry?.tiebreakerAnswers?.[0]?.value ?? "")
  }, [detailedEntry])

  const timeoutIds = React.useRef<Record<string, number>>({
    save: 0,
  })
  const { data: maData, loading: maLoading } = useQuery<MatchupAnalysisQuery, MatchupAnalysisQueryVariables>(MATCHUP_ANALYSIS_QUERY, {
    skip: !matchUpAnalysisInfo,
    variables: {
      homeTeamAbbrev: matchUpAnalysisInfo?.homeTeamAbbrev || "",
      awayTeamAbbrev: matchUpAnalysisInfo?.awayTeamAbbrev || "",
      sportType: (matchUpAnalysisInfo?.sportType as any) || "NCAAB",
      gameInstanceUid,
    },
  })
  const { upsertEntryMutation } = useUpsertEntryMutation(true)

  const scrollIntoFinalFour = () => {
    // RM creating this function just to track analytics as it didn't exist yet
    if (canUseDom) {
      const ffLogoWrap = document.getElementById(BRACKET_FINAL_FOUR_LOGO_ID)
      if (ffLogoWrap) {
        ffLogoWrap.scrollIntoView()
      }
    }
  }

  const handleImportClose = (saved = false) => {
    if (saved) {
      setTimeout(() => {
        // await for the modal to close.
        scrollIntoFinalFour()
      }, 300)
    }
  }

  const closeImportBracketModal = () => setShowImportBracketModal(false)

  useEffect(() => {
    if (isLoggedIn && !entryDetailsIsLoadingOrChanging && !isManagerModeActive && !detailedEntry && isChallengePool && !showNameYourEntryModal) {
      setShowNameYourEntryModal(true)
    }
  }, [isLoggedIn, entryDetailsIsLoadingOrChanging, isManagerModeActive, detailedEntry, isChallengePool])

  if (!pickUtils) {
    return null
  }

  const handleMatchUpAnalysisClick = (
    homeTeamAbbrev: string,
    awayTeamAbbrev: string,
    topPlayInTeamAbbrev: string,
    bottomPlayInTeamAbbrev: string,
    sportType: GameSportTypeEnumType,
    tournamentRound: number,
    slotId: string,
    event: React.SyntheticEvent<Element, Event>,
  ) => {
    const homeTeam = pickUtils.teams.find((x) => x.abbrev === homeTeamAbbrev)
    const awayTeam = pickUtils.teams.find((x) => x.abbrev === awayTeamAbbrev)
    const homeTeamSeed = pickUtils.bracketMapping?.seedByItemId[homeTeam?.id || 0]
    const awayTeamSeed = pickUtils.bracketMapping?.seedByItemId[awayTeam?.id || 0]
    const roundLabel = pickUtils.bracketPeriodTree?.roundNavItemForTournamentRound[tournamentRound].description || ""
    const pick = pickUtils.picks.find((x) => x.slotId === slotId)
    const src = pickUtils.bracketMapping?.picksSlotAndPosItemId || {}
    const topKey = `${slotId}-top`
    const bottomKey = `${slotId}-bottom`
    const topItemId = src[topKey]
    const bottomItemId = src[bottomKey]

    let pickedTeamAbbrev: string | undefined
    if (pick) {
      if (pickUtils.bracketMapping?.itemIdPlayinItemNameOverrides[pick.itemId || ""]) {
        pickedTeamAbbrev = pickUtils.bracketMapping?.itemIdPlayinItemNameOverrides[pick.itemId || ""]
      } else {
        pickedTeamAbbrev = pickUtils.bracketMapping?.itemById[pick.itemId || ""]?.abbrev
      }
    }
    setMatchUpAnalysisInfo({
      homeTeamSeed,
      awayTeamSeed,
      roundLabel,
      pickedTeamAbbrev,
      awayTeamId: bottomItemId,
      homeTeamId: topItemId,
      homeTeamAbbrev,
      awayTeamAbbrev,
      sportType,
      slotId,
      leftSidePlayInTeamAbbrev: topPlayInTeamAbbrev,
      rightSidePlayInTeamAbbrev: bottomPlayInTeamAbbrev,
    })
    Analytics.trackAction("matchup analysis", "matchup", "team selection/interaction")
    setModal("matchup-analysis")
  }

  const isInPretournament = Boolean(centralBracketState?.bracketState?.isPreTournament)

  const displayMode = convertVariantToDisplayMode("picks", isLocked, !poolDetail?.canViewEarly || isInPretournament)
  const visualOptionsGameInstanceUid = getVisualOptionsGameInstanceUID(gameInstanceUid)
  const originalVisualOptions = getVisualOptions(visualOptionsGameInstanceUid, displayMode)
  const [visualOptions, setVisualOptions] = useState<IPartialBracketVisualOptions>(originalVisualOptions)

  const bracketUtils = pickUtils.bracketUtils
  const bracketMapping = pickUtils.bracketMapping
  if (!bracketMapping || !pickUtils.bracketPeriodTree) {
    return null
  }

  const closeNavigationAlert = () => setShowNavigationAlert(false)

  const confirmNavigation = () => {
    setShowNavigationAlert(false)
    setNavigationConfirmed(true)
    PicksExporterUtil.expireCookie(cookieKey)
  }

  const handleNavigation = useCallback(
    (location: History.Location, _: History.Action) => {
      if (navigationConfirmed) {
        return true
      }
      setShowNavigationAlert(true)
      setNavigateTo(location)
      return false
    },
    [navigationConfirmed],
  )

  useEffect(() => {
    if (!(navigationConfirmed && navigateTo)) {
      return
    }
    if (typeof navigateTo === "string") {
      history.push(navigateTo)
    } else {
      history.push(navigateTo.pathname)
    }
  }, [navigationConfirmed, navigateTo, history])

  const hideModal = React.useCallback(() => {
    setModal(null)
  }, [setModal])

  // Shrink the swacket on small devices
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const onChange = () => {
      const viewportW = window.innerWidth
      if (viewportW > bracketTheme.breakpoints.swacketBreakpoint && !bindMouseoverPropogation) {
        // console.log(`bind: ${true}`)
        setBindMouseoverPropogation(true)
      } else if (viewportW < bracketTheme.breakpoints.swacketBreakpoint && bindMouseoverPropogation) {
        // console.log(`bind: ${false}`)
        setBindMouseoverPropogation(false)
      }
      if (visualOptions.swacketMatchupWidth) {
        const isSmall = viewportW <= bracketTheme.breakpoints.shrinkSwacketBreakpoint
        if (isSmall && visualOptions.swacketMatchupWidth > bracketTheme.bracket.swacketShrinkMatchupWidth) {
          setVisualOptions(
            Object.assign({}, visualOptions, {
              swacketMatchupWidth: bracketTheme.bracket.swacketShrinkMatchupWidth,
            }),
          )
        } else if (!isSmall && visualOptions.swacketMatchupWidth < bracketTheme.bracket.swacketMatchupWidth) {
          setVisualOptions(
            Object.assign({}, visualOptions, {
              swacketMatchupWidth: bracketTheme.bracket.swacketMatchupWidth,
            }),
          )
        }
      }
    }
    window.addEventListener("resize", onChange)
    onChange()

    return () => window.removeEventListener("resize", onChange)
  }, [bindMouseoverPropogation, visualOptions])
  pickUtils.bracketUtils.buildCoordinateMap(pickUtils.bracketPeriodTree, visualOptions)
  const initialMatchupIdAlt = getStringParam("initialMatchupId", (canUseDom && window.location.search) || "") || undefined

  useEffect(() => {
    const newPicks =
      detailedEntry?.picks?.map((x: IPickUtilsPick) => {
        const info: IPickUtilsPick = {
          slotId: x.slotId,
          itemId: x.itemId,
        }
        return info
      }) || []
    setWipPicks(newPicks)
  }, [isLocked, entryId, detailedEntry?.picks])

  useEffect(() => {
    if (canUseDom && !isLocked) {
      PicksExporterUtil.hydrate(pickUtils, cookieKey)
        .then((data) => {
          if (data) {
            setExistingPicksExporterData(data)
            setWipPicks(data.picks)
          }
        })
        .catch((err) => console.error(`failed to hydrate picks:`, err))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const changeTournamentRound = useCallback(
    (newTournamentRound: number) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const tr = bracketUtils.clampRound(newTournamentRound, pickUtils.bracketPeriodTree!)
      return new Promise<void>((res) => {
        setviewingTournamentRounds(tr)
        res()
      })
    },
    [bracketUtils, pickUtils.bracketPeriodTree],
  )

  const savePicks = useCallback(
    async (picksToSave: IPickUtilsPicks = [], clearTiebreaker = false) => {
      if (isLocked && !isManagerModeActive) {
        setModal("lock-warning")
        throw new Error(`Picks are locked`)
      }

      const tiebreakerAnswers = clearTiebreaker
        ? []
        : poolHasTiebreaker && !!tiebreakerQuestion
        ? pickUtils.getTiebreakerAnswers([
            {
              tiebreakerQuestionId: tiebreakerQuestion.id,
              value: tiebreakerAnswerValue,
            },
          ])
        : null

      const variables = {
        entryId,
        gameInstanceUid,
        poolId,
        periodId,
        picks: picksToSave,
        tiebreakerAnswers,
        skipPoolData: true,
      } as any
      const response = await upsertEntryMutation({
        variables,
        refetchQueries: ["EntryDetailsQuery", "CentralCurrentUsersEntriesQuery"],
        context: {
          headers: {
            [managerModeHeaderName]: mode,
          },
        },
      })
        .then((rs) => {
          // updateMode(false) // do not want to toggle manager mode off since we present a modal for manager mode pick confirmation
          PicksExporterUtil.expireCookie(cookieKey)
          return rs
        })
        .catch((err) => {
          console.error(err)
          setModal("on-submit-error")
          return null
        })
      return response
    },
    [
      isLocked,
      poolHasTiebreaker,
      tiebreakerQuestion,
      pickUtils,
      tiebreakerAnswerValue,
      entryId,
      gameInstanceUid,
      poolId,
      periodId,
      upsertEntryMutation,
      cookieKey,
      isManagerModeActive,
    ],
  )

  const submitBracket = useCallback(async () => {
    if (isLocked && !isManagerModeActive) {
      setModal("lock-warning")
      throw new Error(`Picks are locked`)
    }
    setIsPersistingPicks({
      saving: true,
      success: false,
    })
    const response = await savePicks(pickUtils?.picks)
    setIsPersistingPicks({
      saving: false,
      success: true,
    })
    setHasChanges(false)
    const updatedEntry = response?.data?.upsertEntry?.entry
    if (isManagerModeActive) {
      setModal("manager-changes")
    } else if (updatedEntry) {
      const isComplete = (updatedEntry.totalPicksCount ?? 0) >= (updatedEntry.maxPicksCount ?? 1)
      const championId = updatedEntry.championTeam?.id
      const upsellData = {
        managerEntryId: updatedEntry.id,
      }
      // const challengePoolId = updatedEntry.pool?.season?.challengePoolId
      const challengePoolId = productSeason?.challengePoolId
      history.push({
        pathname: lobbyMatch?.url || "",
        state: {
          saveBracketInfo: {
            isComplete,
            championId,
            poolIds: isUpsellValue ? [poolId, challengePoolId] : [poolId],
            isChallengePool,
          },
          upsellDataInfo: isUpsellValue ? upsellData : null,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLocked,
    poolHasTiebreaker,
    tiebreakerQuestion,
    pickUtils,
    tiebreakerAnswerValue,
    entryId,
    gameInstanceUid,
    poolId,
    periodId,
    upsertEntryMutation,
    savePicks,
    cookieKey,
    history,
    isUpsellValue,
  ])

  const saveBracket = useCallback(
    async () => {
      try {
        const dateNow = Date.now()

        const bracketLocksAt = centralBracketState?.bracketState?.locksAt || 0

        const pastLockDate = inSweet16Variant
          ? false // centralBracketState?.bracketState.sweetSixteenBracketState.isLocked
          : bracketLocksAt < dateNow
        const locked = isLocked || pastLockDate

        if (locked && !isManagerModeActive) {
          setIsLocked(true)
          setModal("lock-warning")
          throw new Error(`Picks are locked`)
        }

        if (isLoggedIn) {
          setIsPersistingPicks({
            saving: true,
            success: false,
          })
          submitBracket()
          await new Promise((res) => window.setTimeout(res, 1200))
          setIsPersistingPicks({
            saving: false,
            success: true,
          })
        } else {
          queuePickPersisting(800, true)
          setModal("log-in")
        }
      } catch (e) {
        console.log("Warning: ", (e as any).message)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [centralBracketState, isLocked, isLoggedIn, isManagerModeActive, submitBracket],
  )

  useEffect(() => {
    if (bracketSubmitState.submitState === "request") {
      //user clicked the submit button to save picks. Check for incomplete bracket and show modal accordingly
      //check for picks completion
      const maxNumberOfPicks = pickUtils?.getMaxPicksAllowed() ?? Infinity
      const userNumberOfPicks = pickUtils?.picksMade() ?? 0
      const userPicksComplete = userNumberOfPicks >= maxNumberOfPicks

      //check for tiebreaker
      const tiebreakerValue = parseInt(tiebreakerAnswerValue)
      const tiebreakerComplete = !poolHasTiebreaker || !isNaN(tiebreakerValue)

      if (userPicksComplete && tiebreakerComplete) {
        dispatch({ type: "successful_check" })
        return
      }

      if (!userPicksComplete && !tiebreakerComplete) {
        dispatch({
          type: "unsuccessful_check",
          errorType: "Incomplete Bracket",
          error: `You have made ${userNumberOfPicks} of 63 picks and tiebreaker not entered. Do you still want to submit?`,
        })
      } else if (!userPicksComplete) {
        dispatch({
          type: "unsuccessful_check",
          errorType: "Incomplete Bracket",
          error: `You have made ${userNumberOfPicks} of 63 picks. Do you still want to submit?`,
        })
      } else if (!tiebreakerComplete) {
        dispatch({ type: "unsuccessful_check", errorType: "Tiebreaker Not Entered", error: `Do you still want to submit?` })
      }
    } else if (bracketSubmitState.submitState === "warning") {
      setShowIncompleteWarningModal(true)
    } else if (bracketSubmitState.submitState === "saving") {
      dispatch({ type: "completed" })
      saveBracket()
    } else if (bracketSubmitState.submitState === "idle") {
      setShowIncompleteWarningModal(false)
    }
  }, [bracketSubmitState.submitState, tiebreakerAnswerValue, poolHasTiebreaker, pickUtils, saveBracket])

  const confirmIncompleteBracket = useCallback(() => {
    dispatch({ type: "confirm" })
  }, [])

  const cancelIncompleteBracket = useCallback(() => {
    dispatch({ type: "cancel" })
  }, [])

  const handleOnSavePicks = useCallback((event: React.SyntheticEvent) => {
    event.preventDefault()
    event.stopPropagation()
    dispatch({ type: "submit" })
  }, [])

  const onSwitchTeam = (section: "left" | "right") => {
    if (matchUpAnalysisInfo) {
      const { homeTeamAbbrev, awayTeamAbbrev, leftSidePlayInTeamAbbrev, rightSidePlayInTeamAbbrev } = matchUpAnalysisInfo
      if (section === "left" && leftSidePlayInTeamAbbrev) {
        setMatchUpAnalysisInfo({
          ...matchUpAnalysisInfo,
          homeTeamAbbrev: leftSidePlayInTeamAbbrev,
          leftSidePlayInTeamAbbrev: homeTeamAbbrev,
        })
      } else if (section === "right" && rightSidePlayInTeamAbbrev) {
        setMatchUpAnalysisInfo({
          ...matchUpAnalysisInfo,
          awayTeamAbbrev: rightSidePlayInTeamAbbrev,
          rightSidePlayInTeamAbbrev: awayTeamAbbrev,
        })
      }
    }
  }

  pickUtils.bracketUtils.buildCoordinateMap(pickUtils.bracketPeriodTree, visualOptions)
  const cacheKey = (pickUtils.bracketMapping?.cacheKey || "") + viewingTournamentRounds

  const clearTimeoutById = React.useCallback(
    (id: string) => {
      const curr = timeoutIds.current[id]
      if (curr && canUseDom) {
        window.clearTimeout(curr)
        timeoutIds.current[id] = 0
      }
    },
    [timeoutIds],
  )

  const setTimeoutById = React.useCallback(
    (id: string, ms: number, handler: any) => {
      if (timeoutIds.current && canUseDom) {
        clearTimeoutById(id)
        timeoutIds.current[id] = window.setTimeout(handler, ms)
      }
    },
    [timeoutIds, clearTimeoutById],
  )

  const persistPendingPicks = React.useCallback(
    async (isSubmit = false) => {
      if (useCookieForPicks) {
        await PicksExporterUtil.persist(pickUtils, cookieKey, false, isSubmit)
      }
      clearTimeoutById("save")
    },
    [pickUtils, cookieKey, clearTimeoutById, useCookieForPicks],
  )

  const queuePickPersisting = React.useCallback(
    (ms: number, isSubmit?: boolean) => {
      // console.debug(`queuePickPersisting: ${ms} (${this.canQueuePersistingPicks}, ${this.mounted})`);
      setTimeoutById("save", ms, () => persistPendingPicks(isSubmit))
    },
    [persistPendingPicks, setTimeoutById],
  )

  const handleOnItemClick = useCallback(
    async (slotId, itemId, event) => {
      event.preventDefault()
      event.stopPropagation()
      if (isLocked && !isManagerModeActive) {
        setModal("lock-warning")
        throw new Error(`Picks are locked`)
      }
      const eventId = pickUtils.slotIdForEventId(slotId)
      if (eventId) {
        const changes = pickUtils.setChanges(eventId, itemId)
        const merged = pickUtils.getMergedPicks(changes)
        if (!hasChanges) {
          setHasChanges(true)
        }

        let pickedTeamAbbrev: string | undefined
        const pick = merged.find((x) => x.slotId === slotId)
        if (pick) {
          if (pickUtils.bracketMapping?.itemIdPlayinItemNameOverrides[pick.itemId || ""]) {
            pickedTeamAbbrev = pickUtils.bracketMapping?.itemIdPlayinItemNameOverrides[pick.itemId || ""]
          } else {
            pickedTeamAbbrev = pickUtils.bracketMapping?.itemById[pick.itemId || ""]?.abbrev
          }
        }
        if (matchUpAnalysisInfo) {
          setMatchUpAnalysisInfo({
            ...matchUpAnalysisInfo,
            pickedTeamAbbrev,
          })
        }
        setWipPicks(merged)
        queuePickPersisting(800)
      }
    },
    [hasChanges, isLocked, matchUpAnalysisInfo, pickUtils, isManagerModeActive],
  )

  const handleOnPick = useCallback(
    async (slotId, itemId, event) => {
      event.preventDefault()
      event.stopPropagation()
      if (isLocked && !isManagerModeActive) {
        setModal("lock-warning")
        throw new Error(`Picks are locked`)
      }
      const eventId = pickUtils.slotIdForEventId(slotId)
      if (eventId) {
        if (!hasChanges) {
          setHasChanges(true)
        }
        const changes = pickUtils.setChanges(eventId, itemId)
        const merged = pickUtils.getMergedPicks(changes)
        setWipPicks(merged)
        queuePickPersisting(800)
      }
    },
    [hasChanges, isLocked, pickUtils, isManagerModeActive],
  )

  const handleOnClearPicks = React.useCallback(
    (event: React.SyntheticEvent) => {
      event.preventDefault()
      event.stopPropagation()
      setModal("clearpicks")
    },
    [setModal],
  )

  const handleClearAllClick = React.useCallback(async () => {
    const changes = wipPicks.map((pk) => {
      return {
        slotId: pk.slotId,
        itemId: null,
      }
    })
    setWipPicks(changes)
    if (isLoggedIn) {
      setIsPersistingPicks({
        saving: true,
        success: false,
      })
      const clearTiebreaker = poolHasTiebreaker
      await savePicks(changes, clearTiebreaker)
      setIsPersistingPicks({
        saving: false,
        success: true,
      })
    } else {
      PicksExporterUtil.expireCookie(cookieKey)
    }
    hideModal()
  }, [hideModal, savePicks, wipPicks, poolHasTiebreaker, isLoggedIn, cookieKey])

  const onAutoFillClick = React.useCallback(
    (picks: Array<AutoPickQuery_autopick>) => {
      if (!hasChanges) {
        setHasChanges(true)
      }
      const processedPicks = picks.map(({ itemId, tournamentRound, roundOrdinal }) => {
        const matchup = pickUtils.bracketMapping?.matchupByRound[tournamentRound][roundOrdinal]
        return { slotId: matchup?.id ?? "", itemId }
      })
      setWipPicks(processedPicks)
      queuePickPersisting(800)
      setTimeout(() => {
        // await for the modal to close.
        scrollIntoFinalFour()
      }, 1000)
    },
    [hasChanges, pickUtils.bracketMapping?.matchupByRound],
  )

  const onPrintClick = () => {
    // RM creating this function just to track analytics as it didn't exist yet
    setShowPrintable(true)
    Analytics.trackAction("fill bracket", "header", "print")
  }

  const matchupAnalysisModal = (
    <Modal
      modalType={"takeover"}
      padded={false}
      isOpen={modal === "matchup-analysis"}
      onEscapeKeydown={hideModal}
      variant="white"
      noBorder
      header={<MatchUpAnalysisHeader onClose={hideModal} />}
    >
      <AnalyticScreen feature="brackets" subfeature={`matchup-analysis`} title={`Matchup Analysis`} isModal={true} deviceType={deviceType} />
      <MatchUpAnalysis
        {...(matchUpAnalysisInfo || emptyObject)}
        analysisData={maData?.matchupAnalysis as any}
        isLoading={maLoading}
        leftSidePlayInTeamAbbrev={matchUpAnalysisInfo?.leftSidePlayInTeamAbbrev}
        rightSidePlayInTeamAbbrev={matchUpAnalysisInfo?.rightSidePlayInTeamAbbrev}
        onSwitchTeam={onSwitchTeam}
        onPickTeam={handleOnItemClick}
        trackingElements={trackingElements}
        trackingHandler={trackSharedLib}
        pickUtils={pickUtils}
      />
    </Modal>
  )

  const search = getAuthSearch(
    `${lobbyMatch?.url || ""}?gameInstanceUid=${gameInstanceUid}`,
    productSeason?.productAbbrev,
    productSeason?.masterProductId,
    isCbsAppWebview,
  )

  const importBracketModal = <ImportBracketModal isOpen={showImportBracketModal} close={closeImportBracketModal} />

  const clearAllPicksModal = isLocked ? null : (
    <Modal modalType="dialog" padded={false} isOpen={modal === "clearpicks"}>
      <ConfirmClearAll handleCancelClick={hideModal} handleClearAllClick={handleClearAllClick} />
    </Modal>
  )

  const initialModal = isLocked ? null : (
    <Modal modalType="modal" padded={false} isOpen={modal === "initialpicks"} afterClose={hideModal} variant={"white"}>
      <OnFirstPickModal {...(existingPicksExporterData || {})} handleCancelClick={hideModal} />
    </Modal>
  )
  const logInModal = isLocked ? null : (
    <Modal modalType="modal" padded={false} isOpen={modal === "log-in"} afterClose={hideModal} variant={"white"}>
      <BracketLoginModal
        signUpProps={{
          as: Link,
          to: {
            pathname: "/auth/signup",
            search,
          },
        }}
        loginProps={{
          as: Link,
          to: {
            pathname: "/auth/login",
            search,
          },
        }}
        handleCancelClick={hideModal}
      />
    </Modal>
  )
  const discardChangesModal = <DiscardChangesConfirmation isOpen={showNavigationAlert} onClose={closeNavigationAlert} onConfirm={confirmNavigation} />
  const lockWarningModal = (
    <Modal modalType="modal" padded={false} isOpen={modal === "lock-warning"} afterClose={hideModal} variant={"white"}>
      <LockWarningModal handleCancelClick={hideModal} />
    </Modal>
  )
  const onSubmitErrorModal = (
    <GenericConfirmationModal
      isOpen={modal === "on-submit-error"}
      onConfirm={hideModal}
      title="Something went wrong"
      message={`Please try again later.`}
      showCancel={false}
      ctaButtonLabel="Close"
    />
  )
  const incompleteBracketModal = (
    <IncompleteBracketConfirmationModal
      isOpen={showIncompleteWarningModal}
      onClose={cancelIncompleteBracket}
      onConfirm={confirmIncompleteBracket}
      title={bracketSubmitState.errorType}
      message={bracketSubmitState.error}
      selectorLabel={bracketSubmitState.errorType}
    />
  )

  const printableModal = showPrintable ? (
    <Modal isOpen modalType="modal" padded={false} variant="white" onBackgroundClick={closePrintableModal} onEscapeKeydown={closePrintableModal}>
      <PrintableBracketViewer
        title={detailedEntry?.name}
        pickUtils={pickUtils}
        sponsor={isChallengePool ? "NISSAN" : "ATT"}
        variant={inSweet16Variant ? "SWEET16" : isWomens ? "WOMENS" : "REGULAR"}
      />
    </Modal>
  ) : null
  const bpcRulesUrl = getRulesLink(
    constants.NCAAB_CHALLENGE_GAME_INSTANCE_UID,
    productSeason?.season || "",
    productSeason?.productAbbrev || "bpc",
    productSeason?.year || new Date().getFullYear(),
    "disclaimer",
  )
  const gender = isWomens ? `Women` : `Men`
  const bracketUpsellComponent = (
    <BracketUpsellComponent
      onChange={handleTiebreakerChange}
      value={tiebreakerAnswerValue}
      onCheckChange={handleUpsellChange}
      isChecked={isUpsellValue}
      showTiebreakerBox={poolHasTiebreaker}
      showUpsellBox={showUpsellBox}
      disabled={isTiebreakerDisabled}
      disclaimer={`Enter this bracket into Bracket Challenge for the chance to win a trip to the ${
        constants.MARCH_MADNESS_SPORT_YEAR + 2
      } ${gender}'s Final Four${registeredTrademark}. No purchase necessary`}
      rulesUrl={bpcRulesUrl}
    />
  )

  let isPreTournament = isInComingSoon
  if (isInComingSoon && poolDetail?.canViewEarly) {
    isPreTournament = false
  }

  const nameYourEntryModal = (
    <UpsertEntryNameModal
      isOpen={showNameYourEntryModal}
      redirectToLobby={!showUpsellBox}
      title="Name Your Bracket"
      close={() => setShowNameYourEntryModal(false)}
      isCreate={false}
      gameInstanceUid={hard_coded_game_instance}
      isCommingSoon={isInComingSoon}
      mode="bpc"
      seasonType={poolDetail?.season?.season}
      year={poolDetail?.season?.year}
      productAbbrev={isWomens ? "wbpc" : "bpc"}
    />
  )

  const maxPicks = pickUtils.getMaxPicksAllowed()
  const picksCount = pickUtils.picksMade()
  const tiebreakerAnswers = !poolHasTiebreaker
    ? []
    : !!tiebreakerQuestion // TODO/FIXME - Need to remove for 2021 Brackets, putting this here because to make the UI render.
    ? // Seeding for College Football removed the TiebreakerQuestion for bpm
      pickUtils.getTiebreakerAnswers([
        {
          tiebreakerQuestionId: tiebreakerQuestion.id,
          value: tiebreakerAnswerValue,
        },
      ])
    : []

  const isReadOnly = isLoggedIn && !detailedEntry?.isMine && !isManagerModeActive
  const savedPicksCount = detailedEntry?.picks?.length || 0
  const showAutofillInterstitial = !showNameYourEntryModal && !isLocked && !isReadOnly && !isManagerModeActive && savedPicksCount === 0

  const managerChangesMessage = isBracketLock ? (
    <>
      <p>
        Scoring for <strong>{detailedEntry?.name ?? "this participant"}</strong> will be updated at the conclusion of the next game.
      </p>
      <br />
      <p>
        If you are making picks after a completed round, contact <strong>Customer Support</strong> to update standings for the previous round.
      </p>
    </>
  ) : (
    <>
      <p>
        Picks for <strong>{detailedEntry?.name ?? "this participant"}</strong> have been updated.
      </p>
    </>
  )
  const managerChangesModal = (
    <GenericConfirmationModal
      isOpen={modal === "manager-changes"}
      onConfirm={hideModal}
      title="Picks Saved"
      modalIcon={<SuccessfulInfoIcon />}
      message={managerChangesMessage}
      showCancel={false}
      ctaButtonLabel="Close"
    />
  )

  return (
    <SingleBracketWrapper className={inSweet16Variant ? "extra-padding" : ""}>
      {nameYourEntryModal}
      <Prompt message={handleNavigation} when={hasChanges && isLoggedIn} />
      <OptionsMenu>
        {hasManagerRole && (
          <div className="manager-mode-toggle single-bracket-area">
            <Toggle
              onChange={() => {
                if (mode) {
                  updateMode(false, () => {
                    window.location.reload()
                  })
                } else {
                  updateMode(true, () => {
                    window.location.reload()
                  })
                }
              }}
              label="Manager Mode"
              checked={!!mode}
              isMultipleArea
              name="manager-mode"
              inputCheckedBackgroundColor={palette.lightBlue1}
            />
          </div>
        )}
        {!isChallengePool && false && (
          <div className="members-dropdown">
            <PoolBracketsDropdown
              isBracketLocked={isLocked}
              poolId={poolId}
              isManagerModeActive={isManagerModeActive}
              onlyMyBrackets={(poolDetail?.entriesCount || 0) > 100}
            />
          </div>
        )}
        {!isChallengePool && (
          <div className="members-search">
            <SearchEntriesDropdown placeholder="Search Bracket Name" searchOnEnter />
          </div>
        )}
        {isLocked && (
          <Button className="print-bracket" variant="clear" onClick={onPrintClick}>
            <ListItemIcon alignment="left">
              <PrintIcon />
            </ListItemIcon>
            Print Bracket
          </Button>
        )}
      </OptionsMenu>
      <BracketSkeleton
        onItemClick={handleOnPick}
        pickUtils={pickUtils}
        cacheKey={cacheKey}
        handleTournamentRound={changeTournamentRound}
        uiEngine="html"
        MatchupComponent={MatchUpCard as any}
        bindMouseoverPropogation={bindMouseoverPropogation}
        onClearPicks={handleOnClearPicks}
        onMatchUpAnalysisClick={handleMatchUpAnalysisClick}
        // onAutoFillClick={() => setModal("autofill")}
        onSavePicks={handleOnSavePicks}
        displayMode={displayMode}
        isSaving={isPersistingPicks.saving}
        success={isPersistingPicks.success}
        initialMatchupId={initialMatchupIdAlt}
        isLocked={isLocked}
        isPreTournament={isPreTournament}
        canViewEarly={poolDetail?.canViewEarly || undefined}
        variant="picks"
        extraActions={bracketUpsellComponent}
        externalSponsorContent={
          <BracketPresentedBy sponsorName={isChallengePool ? "nissan" : "att"} entryId={entryId} gameInstanceUid={gameInstanceUid} />
        }
        externalToolbar={
          <BracketToolbar
            pickUtils={pickUtils}
            onAutoPickData={onAutoFillClick}
            onPrintClick={onPrintClick}
            onImportClose={handleImportClose}
            showAutofillInterstitial={showAutofillInterstitial}
          />
        }
        readOnly={isReadOnly}
      />
      {!isLocked && (
        <PicksStatus picksCount={picksCount} maxPicks={maxPicks} tiebreakerAnswers={tiebreakerAnswers} poolHasTiebreaker={poolHasTiebreaker} />
      )}

      {matchupAnalysisModal}
      {importBracketModal}
      {clearAllPicksModal}
      {initialModal}
      {logInModal}
      {discardChangesModal}
      {printableModal}
      {lockWarningModal}
      {incompleteBracketModal}
      {onSubmitErrorModal}
      {managerChangesModal}
    </SingleBracketWrapper>
  )
}

export default SingleBracket
