import React, { useContext, useEffect, useState } from "react"
import MyGroups from "../../PoolPages/components/MyGroups/MyGroups"
import { BPCLobbyLocked } from "../../PoolPages/components/MultipleEntriesArea/AddBracketComponent"
import { StandardPoolPageContainer } from "../../PoolPages/components/PoolPage"
import { TPoolRouteProps } from "../../../../routes.d"
import { isCurrentUserLoggedIn } from "../../../utils/data-utils"
import ImportBracketFromCookie from "../../PoolPages/components/EntryModals/BracketLimitModal"
import LatestNews from "../../PoolPages/containers/LatestNews"
import AnalyticScreen from "../../../components/AnalyticsScreen"
import Modal from "@cbs-sports/sports-shared-client/build/cjs/components/Modal"
import JoinedToPoolModal from "../../PoolPages/components/EntryModals/JoinedToPoolModal"
import { useHistory, useLocation } from "react-router-dom"
import { scrollToTopAfterTimeout } from "../../../components/ScrollToTopOnMount"
import constants from "../../../../common/constants"
import SavedBracketModal from "../../PoolPages/components/EntryModals/SavedBracketModal"
import UpsertEntryNameModal from "../../PoolPages/components/EntryModals/UpsertEntryNameModal"
import { SportTypesEnum } from "../../../../common/enums"
import ManagerToChallengeModal from "../../PoolPages/components/EntryModals/ManagerToChallengeModal"
import { isNCAAWTournamentMatcher } from "../../../../common/common-utils-helpers"
import MainPromo from "./MainPromo/MainPromo"
import NewsletterModal from "../../PoolPages/components/EntryModals/NewsletterModal"
import { isCookiePresent } from "../../../../common/cookies"
import { NCAAB_GAME_INSTANCE_UID, NCAAW_GAME_INSTANCE_UID } from "@cbs-sports/sports-shared-client/build/cjs/utils"
import { getNumberOfChallengeEntries } from "../../../hooks/useMainPromoInfo"
import ClientSettingsContext, { ClientSettingsContextType } from "../../../Contexts/ClientSettingsContext"
import JoinChallengePromoItem from "./MainPromo/JoinChallengePromoItem"
import LobbyNeuronAd from "./LobbyNeuronAd"

const MultipleEntriesLobby = (props: TPoolRouteProps) => {
  const { poolData } = props
  const clientSettingsContext: ClientSettingsContextType = useContext(ClientSettingsContext)

  const {
    currentUser,
    myEntriesForArea,
    deviceType,
    centralBracketState,
    hasAutogeneratedName,
    isInComingSoon,
    productSeason,
    gameInstanceUid,
    segmentForArea,
  } = poolData
  const isWomenBracket = isNCAAWTournamentMatcher.test(gameInstanceUid)
  const isLoggedIn = isCurrentUserLoggedIn(currentUser)
  const { currentEntriesForArea: myEntries, alreadyHasChallengeEntry, alreadyHasManagerEntry } = myEntriesForArea()
  const entryWithAutogeneratedName = hasAutogeneratedName && myEntries.find((e) => e.hasAutogeneratedName)
  const seasonType = productSeason?.season ?? "regular"
  const seasonYear = productSeason?.year ?? new Date().getFullYear()
  const visibleEntries = myEntries?.filter((e) => e.isVisible)
  const location = useLocation()
  const history = useHistory()
  const [showModal, setShowModal] = useState<null | string>(null)
  // looks like we may nest this...
  const modalId: string = (history.location.state && history.location.state["modal"] && history.location.state["modal"]["modalId"]) || ""
  const isLocked = centralBracketState?.bracketState?.isLocked
  const sportType = segmentForArea?.sportType || SportTypesEnum.NCAAB
  const showSweetSixteenSignup = constants.SWEET_16_ENABLED
  // isLocked &&
  // !alreadyHasSweet16ChallengeEntry &&
  // !centralBracketState?.bracketState.sweetSixteenBracketState.isPreTournament &&
  // !centralBracketState?.bracketState.sweetSixteenBracketState.isLocked

  const [showMainPromo, setShowMainPromo] = useState(clientSettingsContext?.SHOW_MAIN_BPC_PROMO === "true" || deviceType === "handheld")
  const [showNeuronPromo, setShowNeuronPromo] = useState(clientSettingsContext?.SHOW_NEURON_PROMO === "true")

  useEffect(() => {
    setShowMainPromo(clientSettingsContext?.SHOW_MAIN_BPC_PROMO === "true")
    setShowNeuronPromo(clientSettingsContext?.SHOW_NEURON_PROMO === "true")
  }, [])

  const hideModal = () => {
    const { state } = location
    if (state && state["modal"]) {
      const newState: any = { ...state }
      delete newState.modal
      history.replace({ ...location, state: newState })
    } else {
      history.replace(location)
    }
    setShowModal(null)
  }

  const joinedPoolModal = (
    <Modal
      modalType="modal"
      padded={false}
      isOpen={!!showModal}
      variant={"white"}
      onBackgroundClick={() => undefined}
      onEscapeKeydown={() => undefined}
    >
      <JoinedToPoolModal onClose={hideModal} />
    </Modal>
  )

  useEffect(() => {
    if (modalId) {
      if (!showModal && modalId === JoinedToPoolModal.modalKey) {
        setShowModal(modalId)
      }
    }
  }, [modalId, showModal])

  useEffect(() => {
    return () => {
      scrollToTopAfterTimeout()
    }
  }, [])

  const gameInstanceUidForCookie = isWomenBracket ? NCAAW_GAME_INSTANCE_UID : NCAAB_GAME_INSTANCE_UID
  const mediaCookieName = isWomenBracket ? `${NCAAW_GAME_INSTANCE_UID}-media-picks` : `${NCAAB_GAME_INSTANCE_UID}-media-picks` //TODO: get names from shared lib
  const isMediaCookiePresent = isCookiePresent(mediaCookieName)
  const isPicksCookiePresent = isCookiePresent(`${gameInstanceUidForCookie}_${constants.BRACKET_LOCAL_STORAGE_KEY}`)
  const isRouterStatePresent =
    typeof history.location.state !== "undefined" &&
    ["upsellDataInfo", "saveBracketInfo", "modal", "actions"].some((key) => Boolean(history.location.state?.[key]))
  const newsletterModal = (
    <NewsletterModal
      isOpen={!isWomenBracket && !isMediaCookiePresent && !isPicksCookiePresent && !isRouterStatePresent && deviceType !== "handheld"}
    />
  )

  const challengeEntriesCountBySport = getNumberOfChallengeEntries(currentUser?.entries)
  const impressionTrackingExtra = {
    countOfBrackets: `${challengeEntriesCountBySport?.ncaab || 0}|${challengeEntriesCountBySport?.ncaaw || 0}`,
  }

  return (
    <>
      <StandardPoolPageContainer {...props}>
        <AnalyticScreen
          feature="brackets"
          subfeature={`lobby`}
          title={`Pool Lobby`}
          deviceType={deviceType}
          gameInstanceUid={gameInstanceUid}
          extra={impressionTrackingExtra}
        />
        {showNeuronPromo && <LobbyNeuronAd />}
        {showMainPromo && <MainPromo />}
        <JoinChallengePromoItem
          isLoggedIn={isLoggedIn}
          isFromLobby
          gameInstanceUid={isWomenBracket ? NCAAW_GAME_INSTANCE_UID : NCAAB_GAME_INSTANCE_UID}
          isPreTournament={centralBracketState?.bracketState.isPreTournament}
          seasonType={seasonType}
          seasonYear={seasonYear}
        />
        {!(alreadyHasChallengeEntry || alreadyHasManagerEntry) && isLocked && !showSweetSixteenSignup && (
          <BPCLobbyLocked
            message={
              isWomenBracket
                ? `Brackets have locked for the ${
                    productSeason?.year ?? new Date().getFullYear()
                  } Women's Tournament. We look forward to seeing you next year.`
                : `Brackets have locked for the ${
                    productSeason?.year ?? new Date().getFullYear()
                  } Men's Tournament. We look forward to seeing you next year.`
            }
          />
        )}
        <div>
          <MyGroups isLoggedIn={isLoggedIn} {...props} myEntries={visibleEntries} />
        </div>
        {isLoggedIn && (
          <>
            <ImportBracketFromCookie cookieName={mediaCookieName} />
            <ImportBracketFromCookie cookieName={`${gameInstanceUidForCookie}_${constants.BRACKET_LOCAL_STORAGE_KEY}`} />
            <SavedBracketModal />
            <ManagerToChallengeModal />
            {newsletterModal}
          </>
        )}
        {isLoggedIn && hasAutogeneratedName && entryWithAutogeneratedName && !isLocked && (
          <UpsertEntryNameModal
            title="Name Your Bracket"
            isOpen={hasAutogeneratedName}
            close={() => {
              /* do nothing*/
            }}
            gameInstanceUid={entryWithAutogeneratedName.gameInstanceUid}
            isCommingSoon={isInComingSoon}
            isCreate={false}
            existingEntryDetails={{ id: entryWithAutogeneratedName.id, name: entryWithAutogeneratedName.name }}
            mode={/manager/.test(entryWithAutogeneratedName.gameInstanceUid) ? "bpm" : "bpc"}
            productAbbrev={
              isNCAAWTournamentMatcher.test(entryWithAutogeneratedName.gameInstanceUid)
                ? "wbpc"
                : /manager/.test(entryWithAutogeneratedName.gameInstanceUid)
                ? "bpm"
                : "bpc"
            }
            seasonType={seasonType}
            year={seasonYear}
          />
        )}
        {deviceType !== "handheld" && <LatestNews sportType={sportType} />}
      </StandardPoolPageContainer>
      {joinedPoolModal}
    </>
  )
}

export default MultipleEntriesLobby
